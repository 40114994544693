<template>
<!--  <div class="row m-0 mt-md-5 mb-md-5">-->
  <div class="row m-0 mt-md-5 mb-md-5 m-auto" style="width: 88%">
    <div class="col-md-3">
      <div class="card-header text-white pt-5 pb-5 h4 animate__animated animate__slideInDown">学术委员会&nbsp;顾问</div>
      <div class="list-group p-0">
        <a href="/special" class="bg-light text-dark list-group-item  list-group-item-primary text-left animate__animated animate__slideInDown">学术委员会顾问</a>
      </div>
    </div>
    <div class="col-md-9 animate__animated animate__slideInUp">
      <div class="text-left h4">学术委员会&nbsp;顾问</div>
      <div class="text-left mt-md-1 w-100 row">
<!--        <div class="col-md-2 m-3 card p-0 text-center">-->
<!--          <img class="w-100" src="../../../static/image/people/menmgzuomin.jpg">-->
<!--          <div class="card-footer bg-secondary text-white mt-0 mb-0 pt-1 pb-1 font-weight-bold">孟佐民</div>-->
<!--        </div>-->
        <div class="col-md-3 p-0" v-for="(item,index) in peoListData" :key="index">
          <div class="card-body pr-0">
            <div class="card yishujia">
              <div class="card-body p-0">
                <a href="javascripy:void(0)" @click="artistById(item.peoId)"><img class="w-100 m-0" :src="require('../../../static/image/people/' + item.peoPhoto + '.jpg')"></a>
              </div>
              <div class="card-footer bg-secondary text-white mt-0 mb-0 p-1 text-center font-weight-bold">{{ item.peoName }}</div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "special",
  data() {
    return {
      peoListData: []
    }
  },
  mounted() {
    this.getPeoList(1);
  },
  methods: {
    artistById(peoId) {
      this.$router.push({
        path: '/artistByid/',
        query: { peoId: peoId}
      })
    },
    getPeoList(peoClass) {
      this.$http({
        method: 'get',
        url: '/api/cmsReception/getPeoList/' + peoClass
      }).then((res) =>{
        this.peoListData = res.data.data
      })
    }
  }
}
</script>

<style scoped>
.div-center {
  margin: 0 auto;
}
</style>